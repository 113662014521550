

body {
    padding: 1em;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    color: #000;
    background-color: #f2e6e6;
      }
    .container {
        background-color: #f2e6e6;
        text-align: center;
        max-width:450px;
        margin:0 auto;
        /* box-shadow: 10px 10px 5px #aaaaaa; */
        padding:20px 5px;
      }
  .info{
    text-align:left;
    font-weight:600;
    padding-bottom:10px; 
  }
  .contact-form{
    padding:20px 50px;
    margin: 0 auto;
  }
  input, 
  select option, 
  select{
    width: 100%;
    padding: 1em;
    line-height: 1.4;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
  }


  select#payment_method option[value="Mobile_Banking"]   { background-image:url("../images/mobile1.png");   }
select#payment_method option[value="Tele_Birr"] { background-image:url("../images/tellBirr1.png"); }
select#payment_method option[value="Visa"] { background-image:url("../images/visa1.png"); }
  
  .btn{
    padding:5px 10px;
    background-color:#C1E1C1;
    border-color:#C1E1C1;
  }