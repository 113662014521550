.HeaderSlideA {
  /* margin-top: 100px; */
  width: 100%;
  min-height: 600px;
  /* background: rgba(0, 0, 0, 0.5); */
  border: 1px solid black;
 
}

.banner4 {
  width: 100%;
  margin-top: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0; 

  /* background: rgba(0, 0, 0, 0.5); */
  position: absolute;
  /* border: 1px solid black; */
  
}

.slideImg {
  max-width: 100%;
  width: 2000px;
  height: 700px;
  object-fit: cover;
  /* animation: zoom-out 12s linear infinite;  */
  background: rgba(0, 0, 0, 0.5);
  margin-top: 40px;

  /* border: 1px solid black; */
}

.descPro{
  /* width: 100%; */
  position: absolute;
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  color: white;
 top: 40%;
 right: 40%;
  justify-content: space-around;
  /* flex-direction: column; */
  gap: 20px;
  background-color: rgba(2, 0, 0, 0.4);
  padding: 30px;
  flex-wrap: wrap;
}
@keyframes textUp {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(100px);
  }
  150% {
    transform: translateY(150px);
  }
}

.slide1 {
  animation: fade1 12s linear infinite;
}

/* .slide2 {
  animation: fade2 12s linear infinite;
}

.slide3 {
  animation: fade3 12s linear infinite;
}

.slide4 {
  animation: fade4 12s linear infinite;
} */

@keyframes fade1 {
  0% {
    visibility: visible;
  }

  25% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  75% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes fade2 {
  0% {
    visibility: hidden;
  }

  25% {
    visibility: hidden;
  }

  50% {
    visibility: visible;
  }

  75% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes fade3 {
  0% {
    visibility: hidden;
  }

  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }

  75% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes fade4 {
  0% {
    visibility: hidden;
  }

  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }

  75% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes zoom-out {
  100% {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .HeaderSlideA {
    width: "100px";
    height: 400px;
    margin-top: 100px;
  }

  .banner4{
    margin-top: 0px;
    background: none;
  }

  .slideImg {
    background: none;
    width: 100%;
    animation: none;
    margin-top: 150px;
    height: 600px;
  }

  .slideImg{
    background: none;
  }

  .descPro {
    display: none;
  }

  /* .headerText {
    
    padding: 10px;
    width: 80%;
  } */
}
