.wrapper {
  width: 100%;
  overflow: hidden;
}
.photobanner {
  position: relative;
  height: 200px;
  margin-top: 100px;
  display: flex;
  width: 100%;
  /* border: 1px solid black ; */
}

.product_container {
  position: relative;
}

.product_container:hover .overlay {
  opacity: 1;
  transform: scale(1);
}

.overlay {
  position: absolute;
  /* background-color: rgba(2, 0, 0, 0.4); */
  width: 100%;
  height: 100%;
  top: 0;
  transform: scale(1.1);
  opacity: 0;
  transition: transform 0.4s ease 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.overlay span {
  position: absolute;
  font-size: 2rem;
  font-weight: bold;
  color: rgb(27, 7, 211);
  font-family: Sans-serif;
}

.product_container img {
  transition: transform 0.8s ease;
}

.product_container:hover img {
  transform: scale(1.1);
}

.photobanner img {
  margin: 0px 25px;
  height: 250px;
  width: 300px;
  border-radius: 10px;
}

.photobanner {
  animation: bannermove 50s linear infinite alternate-reverse;
}

@keyframes bannermove {
  from {
    left: 0px;
  }
  to {
    left: -2000px;
  }
}
